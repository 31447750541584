<svelte:options tag="accordion-grid"/>

<script>
    import AccordionItem from './AccordionItem.svelte';

    export let _accordions = accordions; // Get accordions from accordion_grid.html (global variable)

    function handleToggle(event) {
        for (let i = 0; i < _accordions.length; i++) {
            if (_accordions[i].id == event.detail.id && !_accordions[i].open == true) {
                _accordions[i].open = true;
            } else {
                _accordions[i].open = false;
            }
        }
    }

    $: _accordions;
</script>

<div class="class-folder">
    {#each _accordions as accordion, index}
        <accordion-item accordion={accordion} id={accordion.id} name={accordion.id} open={accordion.open} last={index == _accordions.length - 1} on:toggle={handleToggle}></accordion-item>
    {/each}
</div>
