<svelte:options tag="accordion-item"/>

<script>
    import {onMount} from 'svelte';
    import {slide} from 'svelte/transition';
    import {createEventDispatcher} from 'svelte';
    import {get_current_component} from 'svelte/internal';

    const component = get_current_component();
    const svelteDispatch = createEventDispatcher();

    export let accordion = {};
    export let open = false;
    export let last = false;

    const dispatch = (name, detail) => {
        svelteDispatch(name, detail);

        component.dispatchEvent && component.dispatchEvent(
            new CustomEvent(name, {detail})
        );
    };

    onMount(() => {
        const url = window.location.href;
        const hash = new URL(url).hash;

        if (hash) {
            if (hash.replace('#', '') === accordion.id) {
                open = true;

                const accordionGrid = document.querySelector('accordion-grid')
                const accordionItem = accordionGrid.shadowRoot.getElementById(accordion.id)
                accordionItem.scrollIntoView();
            }
        }
    });

    function handleClick(event) {
        event.preventDefault();

        dispatch('toggle', {
            id: accordion.id
        });

        return false;
    }
</script>

<div class="{last ? 'ui-accordion-header last' : 'ui-accordion-header'}" role="tab">
    <!-- svelte-ignore a11y-missing-attribute -->
    <a class="header-link" on:click={handleClick} href="#{accordion.id}"><h2>{accordion.title}</h2>
        <span class="{open ? 'ui-accordion-header-icon open' : 'ui-accordion-header-icon'}">&#8250;</span>
    </a>
</div>

{#if open}
    <div
        class="accordion-content ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom"
        transition:slide={{ duration: 400 }}
    >
        {#if accordion.intro}
            <div class="attribute-short">
                {@html accordion.intro}
            </div>
        {/if}

        {#if accordion.image_jpg}
            <div class="attribute-image">
                <style>
                    .accordion-content .attribute-image img {
                        width: 100%;
                    }
                </style>
                <picture>
                    <source srcset="{accordion.image_webp}" type="image/webp" alt="" width="800">
                    <img src="{accordion.image_jpg}" alt="{accordion.image_alt}" width="800" loading="lazy">
                </picture>
            </div>
        {/if}

        <div class="attribute-long">
            <style>
                .attribute-long *,
                .attribute-long .block-richtext p {
                    font-size: 1.5rem;
                    line-height: 1.5;
                }

                .attribute-long .block-grid .grid.row {
                    display: flex;
                    flex-wrap: wrap;
                }

                .attribute-long .block-grid .grid.row .col {
                    flex-grow: 1;
                    display: flex;
                }

                .attribute-long .block-grid .grid.row .col.l-6 {
                    flex-basis: 50%;
                }

                .attribute-long .block-grid .grid.row .col.l-4 {
                    flex-basis: 33%;
                }

                .attribute-long .block-grid .grid.row .col.l-3 {
                    flex-basis: 25%;
                }

                @media (max-width: 768px) {
                    .attribute-long .block-grid .grid.row {
                        flex-direction: column;
                    }

                    .attribute-long .block-grid .grid.row .col.s-12 {
                        flex-basis: 100%;
                    }
                }


                .attribute-long .block-grid .grid.row .col img {
                    display: block;
                    margin: 1rem;
                    width: 100%;
                    height: auto;

                }
            </style>
            {@html accordion.body}
        </div>

        <div class="cb"></div>
    </div>
{/if}

<style>
    @import '/static/website/norner-theme.css';

    .accordion-content {
        overflow: auto;
    }

    .attribute-short {
        margin-bottom: 15px;
        font-weight: bold;
    }

    .attribute-image {
        width: 50%;
        float: right;
        margin: 0 0 20px 20px;
    }

    .attribute-image img {
        display: block;
    }

    h2 {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-family: 'Exo', sans-serif !important;
        font-weight: 500;
        color: #0060A0;
    }

    a, a:hover {
        color: #0060A0;
        cursor: pointer;
        text-decoration: none;
    }

    .ui-accordion-header {
        border-top: 1px solid #e0e0e0;
    }

    .ui-accordion-header.last {
        border-bottom: 1px solid #e0e0e0;
    }

    .header-link {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ui-accordion-header:focus {
        outline: 0;
    }

    .ui-accordion-header-icon {
        margin-top: 1px;
        font-size: 30px;
        color: #0060A0;
        transform: rotate(0deg);

        -webkit-transition: transform 400ms;
        transition: transform 400ms;
    }

    .ui-accordion-header-icon.open {
        transform: rotate(90deg);
    }

    .ui-accordion-header h2 {
        padding-top: 10px;
        padding-bottom: 5px;
        margin: 0;
        font-size: 20px;
        font-weight: 300;
    }

    .ui-accordion-header a {
        text-decoration: none;
    }
</style>
